

<template>
  <el-empty description="请输入正确的token和电梯注册代码"></el-empty>
</template>


<script>
  import { Loading } from "element-ui";
  import auth from "@/util/loginUtil";

  export default {
    name: "LiftMonitorTransfer",
    data() {
      return {
        id:1,
        loadingInstance: null,
      };
    },
    mounted() {
      const href = window.location.href;
      const queryString = href.split("?")[1];

      const params = new URLSearchParams(queryString);
      if (auth.getToken()) {
        localStorage.removeItem("hold_user_info");
        location.reload(true);

      }

      // 获取 elevatorId 的值
      const registerCode = params.get("registerCode");
      const token  = params.get("token");
      if (token) {
        this.loadingInstance = Loading.service({ fullscreen: true,text:"跳转中" });      // 创建一个 URLSearchParams 实例
        this.getToken(token.replace(/ /g,"+"),registerCode);
        // console.log(token,136);
        // this.getToken("a3q3yrnb9dQSXzd8eCYaeU6Gwvmf/8CyX0RRe9CorPaXGy+3GB0MbADHgYoJHOuJTKo2DvMMqDMiDf1FZiwKZ31u+spwVrmPfOAPkV0V4+uQdUpVAPC+jci5Cb6xQyTw6uj5qXM+2h5+z6YtHr8CttkMzx7MBGVtVxZKn7K2ZJ0JbvQz11g8i96f4rSI+v2aOaJFC6yhgNQyt68BJD8CaMeB9++Z89qt921rVVPTf5MSFOjX++Ym7oarSl+Abd1RFdIJqmZjaAGuj5dRoiLlGxwmmYAoWiprUCq29bZw1rrtebJfXVIw485vefuD4LxQ3yFZYQHlAgKEFu66zO6Q8AdDEEx41Ot+ynX4gBMT+B9gmK/g8Z8pNCwd+wetbnQqpiN/8T2ZLhc7ePch1M19iDLOFbxapZnyt+j5sEeV1g3EPwnoyouX2AxVrwYjcbjftrixsstnAIAR/Y1y8roHwDG5Z6QWr+4yC6lZaVT4EuOMR0CxijDesCye3ygoHgiDTdHn9ys3KQ3qQ5zoEasTkAccfhBlQPnDph9T3OsHIOgXBhulAK/dl6ZHPB27xXf5Cff2L7Wb/LD39k0FQrLLX3E5njf+cQQAXTx4C1EXUf7JuBcOf3Z/FaKmJPJocfHDh6aD59Lw0KG+qOfOQJ5ag97elkDMjNtHorVytq18HQrst4uNsQJQxi71VHojHnkjfonOactyK+mgoUxbsgThv5rcQlHE2HlPTmGWL/x4nJQeRCYSJ8cgJc8B74PkE2IwYHGH0e8W0/vWAurhsMkZgQVK9h7iN7l19fCUtRVteyLaPoMrJEdG4cLM3M7D8b1Cnzt0iEgIdwswvdmhY7mw9UCSybZ/vF5VrZMniA/q5wFt2ncI4Zp4XlTvRI+eABPU/3x7i9jABziPQ3Tb2dD0fLu7o1DzmFc6qmasQyekzMg=",registerCode);
      }
    },
    methods:{
      getToken(token,registerCode) {
        this.$http.get("login/getToken",{token}).then(res => {
          if (res) {
            this.loadingInstance.close();


            this.$router.push({path: "/monitor/LiftMonitor",query:{token:res,registerCode}});
          }else {
            this.loadingInstance.close();
          }
        }).catch(()=>{
          this.loadingInstance.close();
        });
      },

    },
  };

</script>

<style scoped lang="scss">

</style>
